import get from 'lodash/get'
import React from 'react'
import { navigateTo } from 'gatsby-link'
import { Button } from '../../components'
import './style.scss'
import Img from "gatsby-image"

import {
  Container,
  Col,
  Row
} from 'reactstrap'

class SiteProduct extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windowScrollTop: 0,
    }
  }

  render() {
    const product = this.props.data.product
    const title = product.frontmatter.title
    const documentationPath = get(this.props.data, 'documentation.frontmatter.path')
    const path = get(product, 'frontmatter.path')
    const vendorName = get(product, 'frontmatter.vendor')
    const vendorSlug = path && path.split('/')[2]
    const logo = this.props.data?.allImageSharp?.edges.find(e => e.node.fluid.originalName.split('.')[0] === vendorSlug)?.node?.fluid
 
    return (
      <Container>
        <Row style={{alignItems: "center", marginBottom: 20}}>
          <Col lg={2} md={2} xs={12}>
            <Img fluid={logo} alt={vendorName} />
          </Col>
          <Col lg={10} md={10} xs={12}>
            <h1>{title} {vendorName}</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={4} md={8} xs={12}>
            <Img fluid={product.frontmatter.coverImage.childImageSharp.fluid} alt={title} imgStyle={{maxHeight: 400, "objectFit": "contain"}} objectFit="contain" />
          </Col>
          <Col lg={8} md={8} xs={12}>
            <div dangerouslySetInnerHTML={{ __html: product.html }} />
          </Col>
        </Row>
        <Row className="justify-content-center">
          {documentationPath && <Button onClick={() => navigateTo(documentationPath)} color="primary" size="lg" round>
            Voir la documentation
          </Button>}
        </Row>
      </Container>
    )
  }
}

export default SiteProduct
