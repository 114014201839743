import React from 'react'
import Link from 'gatsby-link'

import {
    Nav,
    Navbar,
    NavItem,
	Container,
	Col,
	Row
} from 'reactstrap'

import {
    Button
} from '../../components'

import logo from '../../assets/img/logo.png'

const NavLink = ({children, to, title}) => <Link className="nav-link" to={to} title={title}>{children}</Link>;

class SiteFooter extends React.Component {
	render() {
		return (
			<footer
				className="footer"
			>
				<Container>
					<Row>
						<Col xs={4} md={2} style={{marginTop: 30}}>
							<img src={logo} alt="Logo Irriglobe" style={{width: '100%'}} />
						</Col>
						<Col xs={8} md={10}>
							<Navbar light>
								<Nav>
									<NavItem>
										<div>
											<div style={{textAlign: 'center'}}>
												<a style={{ fontSize: 16, color: '#71bf44', fontWeight: 700 }} href="tel:5149056000">
													514 905-6000
												</a>
											</div>
											<Link title="Nous joindre" to="/nous-joindre">
												<Button color="primary" size="lg" round>
													Nous joindre
												</Button>
											</Link>
										</div>
									</NavItem>
									<NavItem>
										<NavLink title='Accueil' to="/">Accueil</NavLink>
									</NavItem>
									<NavItem>
										<NavLink title='Expertises' to="/compagnie/expertises">Expertises</NavLink>
									</NavItem>
									<NavItem>
										<NavLink title='Nous joindre' to="/nous-joindre">Nous joindre</NavLink>
									</NavItem>
									<NavItem>
										<NavLink title='Carrière' to="/compagnie/emploi">Carrière</NavLink>
									</NavItem>
									<NavItem>
										<NavLink title='Politique de vie privée' to="/compagnie/politique-vie-privee">Politique de vie privée</NavLink>
									</NavItem>
								</Nav>
							</Navbar>
						</Col>
					</Row>
					<Row>
						<Col>
							<div
								className="copyright"
							>
								&copy; {1900 + new Date().getYear()}, Tous droits réservés{' '}
									Irriglobe - RBQ 5701-3914-01
							</div>
						</Col>
					</Row>
				</Container>
			</footer>
		)
	}
}

export default SiteFooter
