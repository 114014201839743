import React from 'react'
import Link from 'gatsby-link';
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import {
    Container,
    Col,
    Row
} from 'reactstrap'

class HomeBrands extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        allImageSharp(filter: {fluid: {originalName: {in: [
                        "toro.png",
                        "irritrol.png",
                        "rainbird.png",
                        "hunter.png",
                        "hydrorain.png",
                        "ez-flo.png",
                        "rachio.png",
                        "premier-tech.png",
                        ]}}}) {
                        edges {
                            node {
                                fluid {
                                    originalName
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        }
                    }
                `}
                render={data => {
                    const allImages = data.allImageSharp.edges;
                    const Toro = allImages.find(e => e.node.fluid.originalName === 'toro.png')?.node.fluid
                    const Irritrol = allImages.find(e => e.node.fluid.originalName === 'irritrol.png')?.node.fluid
                    const Rainbird = allImages.find(e => e.node.fluid.originalName === 'rainbird.png')?.node.fluid
                    const Hunter = allImages.find(e => e.node.fluid.originalName === 'hunter.png')?.node.fluid
                    const Hydrorain = allImages.find(e => e.node.fluid.originalName === 'hydrorain.png')?.node.fluid
                    const EzFlo = allImages.find(e => e.node.fluid.originalName === 'ez-flo.png')?.node.fluid
                    const Rachio = allImages.find(e => e.node.fluid.originalName === 'rachio.png')?.node.fluid
                    const PremierTech = allImages.find(e => e.node.fluid.originalName === 'premier-tech.png')?.node.fluid
                    return (
                        <Container fluid>
                            <Row>
                                <Col lg={5} md={8} xs={12} style={{background: 'rgba(25, 146, 209, 0.05)', alignItems: 'center', justifyContent: 'center', display: 'flex', paddingTop: 40}}>
                                    <div>
                                        <span style={{fontWeight: 500, color: '#1992d1', textTransform: 'uppercase'}}>Marques de Qualité et confiance</span>
                                        <h3 style={{color: '#000'}}>Découvrez nos produits d'irrigation tel que gicleurs, contrôlleurs, irrigation goutte à goutte et bien plus.</h3>
                                    </div>
                                </Col>
                                <Col xs={12} lg={7} style={{padding: '70px 125px'}}>
                                    <Row>
                                        <Col xs={12} md={3} style={{ paddingTop: 50 }}>
                                            <Link title="Rachio" to="/produits/rachio">
                                                <Img fluid={Rachio} alt="Logo Rachio" />
                                            </Link>
                                        </Col>
                                        <Col xs={12} md={3} style={{width: '60%'}}>
                                            <Link title="Toro" to="/produits/toro">
                                                <Img fluid={Toro} alt="Logo Toro" />
                                            </Link>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Link title="Irritrol" to="/produits/irritrol">
                                                <Img fluid={Irritrol} alt="Logo Irritrol" />
                                            </Link>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Link title="Rainbird" to="/produits/rainbird">
                                                <Img fluid={Rainbird} alt="Logo Rainbird" />
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <Link title="Hunter" to="/produits/hunter">
                                                <Img fluid={Hunter} alt="Logo Hunter" />
                                            </Link>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Link title="EzFlo" to="/produits/fertilisation">
                                                <Img fluid={EzFlo} alt="Logo EzFlo" />
                                            </Link>
                                        </Col>
                                        <Col xs={12} md={3} style={{ paddingTop: 45 }}>
                                            <a href="https://www.premiertech.com/fr" target="_blank" rel="noopener noreferrer" title="PremierTech">
                                                <Img fluid={PremierTech} alt="Logo PremierTech" />
                                            </a>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Link title="Hydrorain" to="/produits/hydrorain">
                                                <Img fluid={Hydrorain} alt="Logo Hydrorain" />
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    )
                }}
            />
        );
    };
}

export default HomeBrands;
