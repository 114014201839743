import React from 'react'
import './style.scss'
import Img from "gatsby-image"
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Col,
  Row
} from 'reactstrap'

class SiteDocumentation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windowScrollTop: 0,
    }
  }

  render() {
    const documentation = this.props.data.documentation
    const path = this.props.data.documentation.frontmatter.path
    const title = this.props.data.documentation.frontmatter.title
    const isCityRule = path.includes('reglements')
    const vendor = path && path.split('/')[2]
    const logo = this.props.data?.allImageSharp?.edges.find(e => e.node.fluid.originalName.split('.')[0] === vendor)?.node?.fluid

    return (
      <Container>
        <Row style={{alignItems: "center", marginBottom: 20}}>
          {logo && <Col lg={2} md={2} xs={12}>
            <Img fluid={logo} alt={vendor.toUpperCase()} />
          </Col>}
          <Col lg={10} md={10} xs={12}>
            <h1>{isCityRule ? "Règlement municipal en terme d'arrosage à " + title : "Manuel d'instruction - " + title}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <Card>
              <CardHeader>
                <CardTitle>{!isCityRule && <h2>Documents</h2>}</CardTitle>
              </CardHeader>
              <CardBody
                dangerouslySetInnerHTML={{ __html: documentation.html }}
              />
            </Card>
          </Col>
          <Col lg={4} md={4} xs={12}>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default SiteDocumentation
