import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'

class Statistics extends React.Component {
  render() {
    const icon =
      this.props.icon.indexOf(['jpg|png']) !== -1 ? (
        <div
          className={
            'icon icon-' +
            this.props.iconState +
            (this.props.horizontal ? ' icon-circle' : '')
          }
        >
          <i className={'now-ui-icons ' + this.props.icon} />
        </div>
      ) : (
        <div
          className={
            'icon icon-' +
            this.props.iconState +
            (this.props.horizontal ? ' icon-circle' : '')
          }
        >
          <img src={this.props.icon} alt={this.props.alt} />
        </div>
      )
    const title = (this.props.hasOwnProperty('title'))?<h3 className="info-title">{this.props.title}</h3>:null
    const subtitle = (this.props.hasOwnProperty('subtitle'))?<h6 className="stats-title">{this.props.subtitle}</h6>:null
    return (
      <div
        className={
          this.props.icon.indexOf(['jpg|png']) !== -1
            ? 'statistics'
            : '' + (this.props.horizontal ? ' statistics-horizontal' : '')
        }
      >
        <div
          className={'info' + (this.props.horizontal ? ' info-horizontal' : '')}
        >
          {this.props.horizontal ? (
            <Row>
              <Col xs={5}>{icon}</Col>
              <Col xs={7} className="text-right">
                {title}
                {subtitle}
              </Col>
            </Row>
          ) : (
            <div>
              {icon}
              {title}
              {subtitle}
            </div>
          )}
        </div>
      </div>
    )
  }
}

Statistics.defaultProps = {
  iconState: 'default',
}

Statistics.propTypes = {
  iconState: PropTypes.oneOf([
    'primary',
    'success',
    'info',
    'danger',
    'warning',
    'default',
  ]),
  icon: PropTypes.string,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  horizontal: PropTypes.bool,
}

export default Statistics
