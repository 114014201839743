import React from 'react'
import './style.scss'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Col
} from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import { Button } from '../../components'

import ReactLoading from 'react-loading';

class NewsLetterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windowScrollTop: 0,
      sending: false,
      sent:false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.encode = this.encode.bind(this);
  }

  encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  handleSubmit = (event) => {
    try {
      event.preventDefault();
      var message = ''
      var form = document.getElementById('newsletterForm');
      const data = new FormData(form);
      if(document.getElementById("honeypot").value) {
        return;
      }else if(data.get('email')===''){
        this.showNotification('Veuiller remplir le champ courriel.')
        return;
      }
      this.setState({sending: true})
      fetch('/api/newsletter', {
        method: 'POST',
        body: JSON.stringify({
          firstName: data.get('firstName'),
          lastName: data.get('lastName'),
          email: data.get('email'),
        })
      }).then((data)=>{
        this.setState({sending: false})
        if(data.ok === true && data.status===200){
          this.setState({sent: true})
          message = "Votre êtes inscrit a l'infolettre."
        } else {
          message = 'Il y a eu un problème. Veuillez communiquer avec nous par téléphone.'
        }
        this.showNotification(message)
      }).catch((error)=>{
        this.setState({sending: false})
        message = 'Il y a eu un problème. Veuillez communiquer avec nous par téléphone.'
        this.showNotification(message)
      });
    } catch(e){

    }
  }

  showNotification = (message) => {
    var options = {}
    options = {
      place: 'tr',
      message: message,
      type: 'info',
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7,
    }
    this.refs.notificationAlert.notificationAlert(options)
  }

  render() {
    return (
      <Card className="card-signup" style={{padding: '40px 0'}}>
        <NotificationAlert ref="notificationAlert" />
        <CardHeader className="text-center">
          <CardTitle>
            <span style={{textTransform: 'uppercase', fontWeight: 500, color: '#1992d1', fontSize: '1.2rem'}}>
                Infolettre
            </span>
            <span style={{fontWeight: 700, color: '#000', marginBottom: 10, display: 'block', fontSize: '1.5rem'}}>
              Recevez nos promotions
            </span>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form id="newsletterForm" name="newsletter" method="post" data-netlify="true" data-netlify-honeypot="honeypot" onSubmit={(event) => this.handleSubmit(event)}>
            <div style={{display:'none'}}>
               <label htmlFor="honeypot">Keep this field blank</label>
               <input type="text" name="honeypot" id="honeypot" />
            </div>
            <InputGroup
              className={this.state.firstnameFocus ? 'input-group-focus' : ''}
            >
              <InputGroupAddon addonType="prepend">
                <i className="now-ui-icons users_circle-08" />
              </InputGroupAddon>
              <Input
                type="text"
                name="firstName"
                placeholder="Prénom"
                onFocus={e => this.setState({ firstnameFocus: true })}
                onBlur={e => this.setState({ firstnameFocus: false })}
              />
            </InputGroup>
            <InputGroup
              className={this.state.lastnameFocus ? 'input-group-focus' : ''}
            >
              <InputGroupAddon addonType="prepend">
                <i className="now-ui-icons text_caps-small" />
              </InputGroupAddon>
              <Input
                type="text"
                name="lastName"
                placeholder="Nom de famille"
                onFocus={e => this.setState({ lastnameFocus: true })}
                onBlur={e => this.setState({ lastnameFocus: false })}
              />
            </InputGroup>
            <InputGroup
              className={this.state.emailFocus ? 'input-group-focus' : ''}
            >
              <InputGroupAddon addonType="prepend">
                <i className="now-ui-icons ui-1_email-85" />
              </InputGroupAddon>
              <Input
                type="email"
                name="email"
                placeholder="Courriel"
                onFocus={e => this.setState({ emailFocus: true })}
                onBlur={e => this.setState({ emailFocus: false })}
              />
            </InputGroup>
            {
              (this.state.sending)?
                <Row className="justify-content-center">
                <Col lg={3}>
                <ReactLoading type='bars' color='#1992d1' height={50} width={50} />
                </Col>
                </Row>:
                <Row className="justify-content-center">
                {(this.state.sent)?
                  <Col lg={6} className="text-center">
                    <p>Vous êtes abonné!</p>
                  </Col>
                  :
                  <Col lg={6} style={{textAlign: 'center'}}>
                    <Button color="primary" size="lg" round type="submit">
                      M'inscrire
                    </Button>
                  </Col>
                }
                </Row>
            }
          </Form>
        </CardBody>
      </Card>
    )
  }
}

export default NewsLetterForm
