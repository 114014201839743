import React from 'react'
import './styles.scss';
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import {
    Container,
    Col,
    Row,
} from 'reactstrap'

import {
    NewsLetterForm
  } from '../../components'

import { Parallax, Background } from 'react-parallax'

class HomeNewsLetter extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        allImageSharp(filter: {fluid: {originalName: {in: [
                        "img-bg-infolettre.jpg",
                        ]}}}) {
                        edges {
                            node {
                                fluid {
                                    originalName
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        }
                    }
                `}
                render={data => {
                    const allImages = data.allImageSharp.edges;
                    const InfolettreBg = allImages.find(e => e.node.fluid.originalName === 'img-bg-infolettre.jpg')?.node.fluid

                    let mobile = false
                    try {
                        mobile = window.innerWidth <= 991 ? true : false
                    } catch (e) {}
                    
                    return (
                        <Parallax
                            disabled={mobile}
                            className="newsletter-section"
                        >
                            <Background>
                                <Img fluid={InfolettreBg} alt="Entretien gazon" />
                            </Background>
                            <Container>
                                <Row>
                                    <Col lg={7} md={6} sm={12} xs={12}>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12} style={{alignItems: 'center', justifyContent: 'center', display: 'flex', height: 515}}>
                                        <NewsLetterForm page="homepage" />
                                    </Col>
                                </Row>
                            </Container>
                        </Parallax>
                    )
                }}
            />
        );
    };
}

export default HomeNewsLetter;
