import React from 'react'
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
} from 'reactstrap'
import { Button } from '../../components'
import Search from '../../components/Search'

import logoMini from '../../assets/img/logo-mini.png'

class SiteHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      isWideEnough: false,
    }
    this.openSidebar = this.openSidebar.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    })
  }

  openSidebar() {
    document.documentElement.classList.toggle('nav-open')
    this.refs.sidebarToggle.classList.toggle('toggled')
  }

  render() {
    return (
      <Navbar
        className="fixed-top navbar-expand-lg bg-secondary site-header"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref="sidebarToggle"
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="/">
              <img alt="logo irriglobe" className="logo-mobile" src={logoMini} />
            </NavbarBrand>
          </div>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            <Nav navbar>
              <NavItem>
                <Search {...this.props} />
              </NavItem>
            </Nav>
          </Collapse>
          <a style={{ fontSize: 16, marginRight: 10, marginLeft: 10 }} href="tel:5149056000">
            514 905-6000
          </a>
          <Button
            icon
            round
            facebook
            href='https://www.facebook.com/irriglobe'
          >
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 112.196 112.196" style={{'enableBackground': 'new 0 0 112.196 112.196'}}>
              <g>
                <circle style={{fill:'#3B5998'}} cx="56.098" cy="56.098" r="56.098"/>
                <path style={{fill:'#FFFFFF'}} d="M70.201,58.294h-10.01v36.672H45.025V58.294h-7.213V45.406h7.213v-8.34
                  c0-5.964,2.833-15.303,15.301-15.303L71.56,21.81v12.51h-8.151c-1.337,0-3.217,0.668-3.217,3.513v7.585h11.334L70.201,58.294z"/>
              </g>
            </svg>
          </Button>
        </Container>
      </Navbar>
    )
  }
}

export default SiteHeader
