import React from 'react'
import './styles.scss';
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import {
    CardAuthorIcon
} from '../../components'

import {
    Card,
    CardBody,
    Container,
    Col,
    Row,
} from 'reactstrap'

import { Parallax, Background } from 'react-parallax'

class HomeAbout extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        allImageSharp(filter: {fluid: {originalName: {in: [
                        "sprinkler.png",
                        "about-bg.jpg",
                        ]}}}) {
                        edges {
                            node {
                                fluid {
                                    originalName
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        }
                    }
                `}
                render={data => {
                    const allImages = data.allImageSharp.edges;
                    const Sprinkler = allImages.find(e => e.node.fluid.originalName === 'sprinkler.png')?.node.fluid
                    const About = allImages.find(e => e.node.fluid.originalName === 'about-bg.jpg')?.node.fluid

                    let mobile = false
                    try {
                        mobile = window.innerWidth <= 991 ? true : false
                    } catch (e) {}

                    return (
                        <Parallax
                            disabled={mobile}
                            className="about-section"
                        >
                            <Background>
                                <Img fluid={About} alt="Gazon" />
                                <div className="sprinkler">
                                    <Img fluid={Sprinkler} alt="Gicleur Irriglobe" />
                                </div>
                            </Background>
                            <Container>
                                <Row>
                                    <Col style={{textAlign: 'center'}}>
                                        <h3 style={{color: '#000'}}>
                                            Irriglobe, le spécialiste en irrigation!
                                        </h3>
                                    </Col>
                                </Row>
                                <Row style={{justifyContent: 'center'}}>
                                    <Col lg={3} md={6} sm={12} xs={12}>
                                        <Card className="card-user">
                                            <CardBody>
                                                <CardAuthorIcon
                                                    link="nous-joindre"
                                                    class="card-icon now-ui-icons location_pin"
                                                    avatarAlt="..."
                                                    title="Irriglobe, partout au Québec"
                                                    description="Notre compagnie, desservant tout le Québec, se spécialise dans l’installation de systèmes d’irrigation et d’arrosage automatique et est axé sur la qualité du produit dans le domaine de l’irrigation."
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={3} md={6} sm={12} xs={12}>
                                        <Card className="card-user">
                                            <CardBody>
                                                <CardAuthorIcon
                                                    link="services"
                                                    class="flat-icons flat-university card-icon icon-secondary"
                                                    avatarAlt="..."
                                                    title="Résidentiel et Commercial"
                                                    description="La Compagnie Irriglobe Inc. est une compagnie en pleine expansion dans le domaine de l’irrigation résidentielle et commerciale."
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={3} md={6} sm={12} xs={12}>
                                        <Card className="card-user">
                                            <CardBody>
                                                <CardAuthorIcon
                                                    link="compagnie/expertises"
                                                    class="card-icon now-ui-icons ui-2_like"
                                                    avatarAlt="..."
                                                    title="La force de l'expérience avant tout"
                                                    description="Fondé en 2002, Irriglobe est une entreprise de confiance lancée par un entrepreneur dynamique et travaillant, qui au fil du temps à su se faire une place majeure dans le domaine."
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </Parallax>
                    );
                }}
            />
        );
    };
}

export default HomeAbout;
