import React, {Component} from 'react';
import { Index } from 'elasticlunr';
import Link from 'gatsby-link'

import {
    InputGroup, InputGroupAddon, Input
} from 'reactstrap';

export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: ``,
            results: [],
            focused: false
        };
    }

    getOrCreateIndex = () =>
        this.index
            ? this.index
            : Index.load(this.props.searchIndex);

    search = (evt) => {
        const query = evt.target.value;
        this.index = this.getOrCreateIndex();
        this.setState({
            query,
            results: this.index.search(query)
            .map(({
                ref,
            }) => this.index.documentStore.getDoc(ref)),
        });
    }

    onBlur = () => {
        setTimeout(() => this.setState({ focused: false }), 500)
    }

    onFocus = () => {
        this.setState({ focused: true })
    }

    render() {
        return (
            <div className="search">
                <form>
                    <InputGroup className="no-border">
                        <InputGroupAddon addonType="prepend"><i style={{marginTop: 5}} className="now-ui-icons ui-1_zoom-bold" /></InputGroupAddon>
                        <Input type="text" value={this.state.query} onChange={this.search} placeholder="Rechercher"  onFocus={this.onFocus} onBlur={this.onBlur} />
                    </InputGroup>
                </form>
                <ul style={{display: this.state.focused ? 'block' : 'none'}}>
                    {this.state.results.length > 0 ? this.state.results.map((page, index) => (
                        <li key={index}>
                            <Link to={page.path}>
                                {page.title}
                            </Link>
                        </li>
                    )):null}
                    {this.state.query.length > 0 && this.state.results.length === 0 ? 
                        <li key={0}>
                            <a>Aucun résultat</a>
                        </li> : ''
                    }
                </ul>
            </div>
        );
    }
}
