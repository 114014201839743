import React from 'react'
import MobileMenu from '../components/MobileMenu'
import DesktopMenu from '../components/DesktopMenu'
import SiteHeader from '../components/SiteHeader'
import SiteFooter from '../components/SiteFooter'
import { StaticQuery } from "gatsby"
import { graphql } from "gatsby"

import 'bootstrap/dist/css/bootstrap.css'
import '../assets/scss/now-ui-dashboard.scss'
import '../assets/scss/custom.scss'

class Template extends React.Component {
  componentDidUpdate() {
    try {
      setTimeout(()=>{
        window.Tawk_API.addEvent('navigate-to', {
          'page': this.props.location.pathname
        }, function (error) {
        });
      }, 2000);
    } catch (e) { }
  }

  render() {
    const { location, children } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SearchIndexQuery {
            siteSearchIndex {
              index
            }
          }
        `}
        render={data => (<div>
          <MobileMenu location={location} searchIndex={data.siteSearchIndex.index} />
          <SiteHeader location={location} searchIndex={data.siteSearchIndex.index} />  
          <DesktopMenu location={location} />
          <div className="main">{children}</div>
          <SiteFooter location={location} />
        </div>)}
      />
    )
  }
}

export default Template