import React from 'react'
import './style.scss'

import {
  Container,
  Col,
  Row
} from 'reactstrap'

class SiteProductFullwidth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windowScrollTop: 0,
    }
  }

  render() {
    const product = this.props.data.product
    const title = this.props.data.product.frontmatter.title
    return (
      <Container>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <h1>{title}</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={12} md={12} xs={12}>
            <div dangerouslySetInnerHTML={{ __html: product.html }} />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default SiteProductFullwidth
