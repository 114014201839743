import React from 'react'
import Link from 'gatsby-link'
import {
  Collapse,
  Nav
} from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import PerfectScrollbar from 'perfect-scrollbar'
import logo from '../../assets/img/logo.png'
import logoMini from '../../assets/img/logo-mini.png'
import Search from '../../components/Search'

var ps

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      company: false,
      services: false,
      products: false,
      documentations: false,
    }
    this.minimizeSidebar = this.minimizeSidebar.bind(this)
  }

  minimizeSidebar() {
    var message = 'Menu fixe '
    if (document.body.classList.contains('sidebar-mini')) {
      message += 'désactivé.'
    } else {
      message += 'activé.'
    }
    document.body.classList.toggle('sidebar-mini')
    document.body.classList.toggle('sidebar-sticky')
    var options = {}
    options = {
      place: 'tr',
      message: message,
      type: 'info',
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7,
    }
    this.refs.notificationAlert.notificationAlert(options)
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
    }
    try{
      document.querySelector('.main').addEventListener('click', this.closeSidebar);
      var windowHeight = window.innerHeight
      document.querySelector('.sidebar-wrapper').setAttribute("style", "height:"+ windowHeight+"px");
      window.addEventListener('resize', function(){
        document.querySelector('.sidebar-wrapper').setAttribute("style", "height:"+ windowHeight+"px");
      })
    } catch(e){

    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
    }
    try{
      document.querySelector('.main').removeEventListener('click', this.closeSidebar);
    } catch(e){

    }
  }

  closeSidebar(){
    document.documentElement.classList.remove('nav-open')
    document.querySelector('.navbar-toggler').classList.remove('toggled')
  }

  openSidebar() {
    document.documentElement.classList.toggle('nav-open')
    document.querySelector('.navbar-toggler').classList.toggle('toggled')
  }

  render() {

    const pathname = '';

    return (
      <div className="sidebar hide-desktop" data-color="white">
        <NotificationAlert ref="notificationAlert" />
        <div className="sidebar-wrapper" ref="sidebar">
          <div className="logo">
            <Link title="Accueil" onClick={()=>this.openSidebar()} to="/" className="logo-mini">
              <img alt="Logo Irriglobe" className="logo-scroll" src={logoMini} height="40" />
            </Link>
            <Link title="Ouvrir le menu" onClick={()=>this.openSidebar()} to="/" className="logo-normal">
              <img alt="Logo Irriglobe" src={logo} height="75" />
            </Link>
            <div className="navbar-minimize">
              <a
                simple="true"
                neutral="true"
                icon="true"
                round="true"
                id="minimizeSidebar"
                onClick={this.minimizeSidebar}
              >
                <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
              </a>
            </div>
          </div>
          <Nav>

            <li style={{paddingLeft: 10, paddingRight: 10}}>
              <Search {...this.props} />
            </li>

            <li
              className={
                pathname === '/compagnie/expertises' ? 'active' : ''
              }
            >
              <Link title="Expertises" onClick={()=>this.openSidebar()} to="/compagnie/expertises" className="nav-link">
                <i className={'now-ui-icons business_badge'} />
                <p>Expertises</p>
              </Link>
            </li>
            <li>
              <a
                title="Voir nos services"
                data-toggle="collapse"
                aria-expanded={this.state.services}
                onClick={() =>
                  this.setState({ services: !this.state.services })
                }
              >
                <i className={'now-ui-icons shopping_delivery-fast'} />
                <p>
                  Services
                  <b className="caret" />
                </p>
              </a>
              <Collapse isOpen={this.state.services}>
                <ul className="nav">
                  <li
                    className={
                      pathname === '/services'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Tous nos services" onClick={()=>this.openSidebar()} to="/services">
                      <span className="sidebar-normal">Tous nos Services</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/services/irrigation-residentielle'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Irrigation résidentielle" onClick={()=>this.openSidebar()} to="/services/irrigation-residentielle">
                      <span className="sidebar-normal">Résidentiel</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/services/irrigation-commercialle'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Irrigation commerciale" onClick={()=>this.openSidebar()} to="/services/irrigation-commerciale">
                      <span className="sidebar-normal">Commercial</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname ===
                      '/services/irrigation-municipale'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Irrigation municipale" onClick={()=>this.openSidebar()} to="/services/irrigation-municipale">
                      <span className="sidebar-normal">Municipal</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/services/irrigation-toits-vert'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Irrigation toîts vert" onClick={()=>this.openSidebar()} to="/services/irrigation-toits-vert">
                      <span className="sidebar-normal">Toîts vert</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/services/agriculture-urbaine'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Agriculture urbaine" onClick={()=>this.openSidebar()} to="/services/agriculture-urbaine">
                      <span className="sidebar-normal">Agriculture urbaine</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/services/dispositif-danti-refoulement'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Dispositif d'anti-refoulement" onClick={()=>this.openSidebar()} to="/services/dispositif-danti-refoulement">
                      <span className="sidebar-normal">Dispositif d'anti-refoulement</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/services/conception-de-plans'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Conception de plans" onClick={()=>this.openSidebar()} to="/services/conception-de-plans">
                      <span className="sidebar-normal">Conception de plans</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/services/irrigation-goutte-a-goutte'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Irrigation goutte à goutte" onClick={()=>this.openSidebar()} to="/services/irrigation-goutte-a-goutte">
                      <span className="sidebar-normal">Irrigation goutte à goutte</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/services/recuperation-eau-de-pluie'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Récupération eau de pluie" onClick={()=>this.openSidebar()} to="/services/recuperation-eau-de-pluie">
                      <span className="sidebar-normal">Récupération eau de pluie</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/services/entretien-annuel'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Entretien annuel" onClick={()=>this.openSidebar()} to="/services/entretien-annuel">
                      <span className="sidebar-normal">Entretien annuel</span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <a
                title="Voir nos produits"
                data-toggle="collapse"
                aria-expanded={this.state.products}
                onClick={() =>
                  this.setState({ products: !this.state.products })
                }
              >
                <i className={'now-ui-icons shopping_shop'} />
                <p>
                  Produits
                  <b className="caret" />
                </p>
              </a>
              <Collapse isOpen={this.state.products}>
                <ul className="nav">
                  <li
                    className={
                      pathname === '/produits'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="À propos de nos produits" onClick={()=>this.openSidebar()} to="/produits">
                      <span className="sidebar-normal">À propos de nos produits</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/irriglobe'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits Irriglobe" onClick={()=>this.openSidebar()} to="/produits/irriglobe">
                      <span className="sidebar-normal">Irriglobe</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/hydrorain'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits HydroRain" onClick={()=>this.openSidebar()} to="/produits/hydrorain">
                      <span className="sidebar-normal">HydroRain</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/rachio'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits Rachio" onClick={()=>this.openSidebar()} to="/produits/rachio">
                      <span className="sidebar-normal">Rachio</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/toro'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits Toro"  onClick={()=>this.openSidebar()} to="/produits/toro">
                      <span className="sidebar-normal">Toro</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/irritrol'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits Irritrol"  onClick={()=>this.openSidebar()} to="/produits/irritrol">
                      <span className="sidebar-normal">Irritrol</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/rainbird'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits Rainbird" onClick={()=>this.openSidebar()} to="/produits/rainbird">
                      <span className="sidebar-normal">Rainbird</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/hunter'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits Hunter" onClick={()=>this.openSidebar()} to="/produits/hunter">
                      <span className="sidebar-normal">Hunter</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/fertilisation'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits EzFlo" onClick={()=>this.openSidebar()} to="/produits/fertilisation">
                      <span className="sidebar-normal">Fertilisation Ez-Flo</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/rewatec'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits Rewatec" onClick={()=>this.openSidebar()} to="/produits/rewatec">
                      <span className="sidebar-normal">Rewatec</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/produits/k-rain'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Produits K-Rain" onClick={()=>this.openSidebar()} to="/produits/k-rain">
                      <span className="sidebar-normal">K-Rain</span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <a
                title="Voir notre documentation"
                data-toggle="collapse"
                aria-expanded={this.state.documentations}
                onClick={() =>
                  this.setState({ documentations: !this.state.documentations })
                }
              >
                <i className={'now-ui-icons files_single-copy-04'} />
                <p>
                  Documentations
                  <b className="caret" />
                </p>
              </a>
              <Collapse isOpen={this.state.documentations}>
                <ul className="nav">
                  <li
                    className={
                      pathname === '/documentations/hydrorain'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions Toro" onClick={()=>this.openSidebar()} to="/documentations/hydrorain">
                      <span className="sidebar-normal">HydroRain</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/documentations/toro'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions Toro" onClick={()=>this.openSidebar()} to="/documentations/toro">
                      <span className="sidebar-normal">Toro</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === '/documentations/hunter'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions Hunter" onClick={()=>this.openSidebar()} to="/documentations/hunter">
                      <span className="sidebar-normal">Hunter</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname ===
                      '/documentations/irritrol'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions Irritrol" onClick={()=>this.openSidebar()} to="/documentations/irritrol">
                      <span className="sidebar-normal">Irritrol</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname ===
                      '/documentations/rainbird'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions Rainbird" onClick={()=>this.openSidebar()} to="/documentations/rainbird">
                      <span className="sidebar-normal">Rainbird</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname ===
                      '/documentations/reglements'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions règlements municipaux" onClick={()=>this.openSidebar()} to="/documentations/reglements">
                      <span className="sidebar-normal">Règlements municipaux</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname ===
                      '/documentations/irriglobe'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions Irriglobe" onClick={()=>this.openSidebar()} to="/documentations/irriglobe">
                      <span className="sidebar-normal">Irriglobe</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname ===
                      '/documentations/rachio'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions Rachio" onClick={()=>this.openSidebar()} to="/documentations/rachio">
                      <span className="sidebar-normal">Rachio</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname ===
                      '/documentations/k-rain'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions K-Rain" onClick={()=>this.openSidebar()} to="/documentations/k-rain">
                      <span className="sidebar-normal">K-Rain</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname ===
                      '/documentations/ez-flo'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions Ez-Flo" onClick={()=>this.openSidebar()} to="/documentations/ez-flo">
                      <span className="sidebar-normal">Ez-Flo</span>
                    </Link>
                  </li>
                  <li
                    className={
                      pathname ===
                      '/documentations/rewatec'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link title="Documentions Rewatec" onClick={()=>this.openSidebar()} to="/documentations/rewatec">
                      <span className="sidebar-normal">Rewatec</span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li
              className={
                pathname === '/nous-joindre' ? 'active' : ''
              }
            >
              <Link title="Nous joindre" onClick={()=>this.openSidebar()} to="/nous-joindre" className="nav-link">
                <i className={'now-ui-icons ui-1_email-85'} />
                <p>Nous joindre</p>
              </Link>
            </li>
          </Nav>
        </div>
      </div>
    )
  }
}

export default MobileMenu
