import React from 'react'

import {
    Form,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
} from 'reactstrap'

import NotificationAlert from 'react-notification-alert'

import { FormInputs, Button } from '../../components'

import ReactLoading from 'react-loading';

class ContactForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sending: false,
            sent: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showNotification = this.showNotification.bind(this);
    }

    handleSubmit = (event) => {
        try {
            event.preventDefault();
            var message = ''
            var form = document.getElementById('contactForm');
            const data = new FormData(form);
            if (document.getElementById("honeypot").value) {
                return;
            } else if (data.get('email') === '') {
                this.showNotification('Veuiller remplir le champ courriel.')
                return;
            }
            this.setState({ sending: true })
            fetch('https://www.irriglobe.com/contact.php', {
                method: 'POST',
                body: data
            }).then((data) => {
                this.setState({ sending: false })
                if (data.ok === true && data.status === 200) {
                    message = "Votre message a bien été envoyé."
                    this.setState({ sent: true })
                } else {
                    message = 'Il y a eu un problème. Veuillez communiquer avec nous par téléphone.'
                }
                this.showNotification(message)
            }).catch((error) => {
                this.setState({ sending: false })
                message = 'Il y a eu un problème. Veuillez communiquer avec nous par téléphone.'
                this.showNotification(message)
            });
        } catch (e) {

        }
    }

    showNotification = (message) => {
        var options = {}
        options = {
            place: 'tr',
            message: message,
            type: 'info',
            icon: 'now-ui-icons ui-1_bell-53',
            autoDismiss: 7,
        }
        this.refs.notificationAlert.notificationAlert(options)
    }

    render() {

        return (
            <>
                <NotificationAlert ref="notificationAlert" />
                <Card>
                    <CardHeader>
                        <CardTitle><h3>Remplissez ce formulaire pour obtenir une soumission en ligne.</h3></CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Form id="contactForm" name="contact" method="post" data-netlify="true" data-netlify-honeypot="honeypot" onSubmit={this.handleSubmit}>
                            <div style={{ display: 'none' }}>
                                <label htmlFor="honeypot">Keep this field blank</label>
                                <input type="text" name="honeypot" id="honeypot" />
                            </div>
                            <FormInputs
                                ncols={['col-12', 'col-12', 'col-12', 'col-12', 'col-12']}
                                proprieties={[
                                    {
                                        label: 'Nom',
                                        inputProps: {
                                            type: 'text',
                                            name: 'name'
                                        },
                                    },
                                    {
                                        label: 'Courriel',
                                        inputProps: {
                                            type: 'email',
                                            name: 'email'
                                        },
                                    },
                                    {
                                        label: 'Téléphone',
                                        inputProps: {
                                            type: 'phone',
                                            name: 'phone'
                                        },
                                    },
                                    {
                                        label: 'Description du projet',
                                        inputProps: {
                                            type: 'textarea',
                                            name: 'message'
                                        },
                                    },
                                    {
                                        label: 'Ajouter des documents',
                                        inputProps: {
                                            type: 'file',
                                            name: 'file[]',
                                            multiple: 'multiple'
                                        },
                                    },
                                ]}
                            />
                            {
                                (this.state.sending) ?
                                    <Row className="justify-content-center">
                                        <Col lg={3}>
                                            <ReactLoading type='bars' color='#1992d1' height={50} width={50} />
                                        </Col>
                                    </Row> :
                                    <Row className="justify-content-center">
                                        {(this.state.sent) ?
                                            <Col className="text-center">
                                                <p>Message envoyé</p>
                                            </Col>
                                            :
                                            <Col className="text-center">
                                                <Button color="primary" size="lg" round type="submit">
                                                    Envoyer
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                            }
                        </Form>
                    </CardBody>
                </Card>
            </>
        )
    }
}

export default ContactForm;
