import React from 'react'
import Link from 'gatsby-link';
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import {
    Container,
    Col,
    Row
} from 'reactstrap'

class HomeLocalProduct extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        allImageSharp(filter: {fluid: {originalName: {in: [
                        "produits-dici.png",
                        ]}}}) {
                        edges {
                            node {
                                fluid {
                                    originalName
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        }
                    }
                `}
                render={data => {
                    const allImages = data.allImageSharp.edges;
                    const ProduitsIci = allImages.find(e => e.node.fluid.originalName === 'produits-dici.png')?.node.fluid
                    return (
                        <Container fluid>
                            <Row>
                                <Col lg={1} md={6} xs={12}></Col>
                                <Col lg={2} md={6} xs={12} style={{paddingTop: 75}}>
                                    <Link title="Produits d'ici" to="/produits/arroseur-rps-irriglobe">
                                        <Img fluid={ProduitsIci} alt="Produits d'ici" />
                                    </Link>
                                </Col>
                                <Col xs={12} lg={8} style={{paddingTop: 100}}>
                                    <Row>
                                        <Col xs={12} md={12}>
                                         <h3>Pour des raisons environnementales et pour encourager l’économie d’ici, Irriglobe privilégie les produits locaux, canadiens ou québécois. Nous sommes Fiers d’encouragé l’économie d’ici ses pourquoi toutes nos tubulures provienne d’un manufacturier du quebec et que nous travaillons sans cesse à développé des gicleurs pour notre climat d’ici.</h3>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    )
                }}
            />
        );
    };
}

export default HomeLocalProduct;