import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'

class CardAuthorIcon extends React.Component {
  render() {
    return (
      <div className="author">
        <a href={this.props.link ? this.props.link : '#'}>
          <i
            className={this.props.class}
          />
          <h3 className="title" style={{fontSize: "1.5em"}}>{this.props.title}</h3>
        </a>
        <p className="description">{this.props.description}</p>
      </div>
    )
  }
}

CardAuthorIcon.propTypes = {
  // Where the user to be redirected on clicking the avatar
  link: PropTypes.string,
  avatar: PropTypes.string,
  avatarAlt: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default CardAuthorIcon
